import React from 'react'
import Block from '../components/Block'
import Layout from '../components/Layout'
import Page from '../components/Page'
import { TermsAndConditions } from '../components/TermsAndConditions'

const TermsPage = () => (
  <Layout justifyCenter title="Terms of Service">
    <Page>
      <Block size="medium">
        <TermsAndConditions />
      </Block>
    </Page>
  </Layout>
)

export default TermsPage
